var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Slug:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"slug"},model:{value:(_vm.doctorsSpecialitiesData.slug),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "slug", $$v)},expression:"doctorsSpecialitiesData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Название"},model:{value:(_vm.doctorsSpecialitiesData.name),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "name", $$v)},expression:"doctorsSpecialitiesData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Описание:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите описание","rows":"3"},model:{value:(_vm.doctorsSpecialitiesData.description),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "description", $$v)},expression:"doctorsSpecialitiesData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Дополнительное описание:","label-for":"additional_description"}},[_c('ValidationProvider',{attrs:{"name":"additional_description","vid":"additional_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите дополнительное описание","rows":"3"},model:{value:(_vm.doctorsSpecialitiesData.additional_description),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "additional_description", $$v)},expression:"doctorsSpecialitiesData.additional_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Тип:","label-for":"type"}},[_c('ValidationProvider',{attrs:{"name":"type","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"is_show","value-field":"id","text-field":"value","options":_vm.DoctorSpecialitiesConstants.DOCTOR_SPECIALITIES_TYPE_LIST},model:{value:(_vm.doctorsSpecialitiesData.type),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "type", $$v)},expression:"doctorsSpecialitiesData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Slug DOCDOC:","label-for":"docdoc_slug"}},[_c('ValidationProvider',{attrs:{"name":"docdoc_slug","vid":"docdoc_slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"docdoc_slug","autocomplete":"off","placeholder":"Введите Slug с DOCDOC"},model:{value:(_vm.doctorsSpecialitiesData.docdoc_slug),callback:function ($$v) {_vm.$set(_vm.doctorsSpecialitiesData, "docdoc_slug", $$v)},expression:"doctorsSpecialitiesData.docdoc_slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.doctorsSpecialitiesData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }