<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Slug:"
          label-for="slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="slug"
            vid="slug"
          >
            <b-form-input
              id="slug"
              v-model="doctorsSpecialitiesData.slug"
              autocomplete="off"
              placeholder="slug"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="doctorsSpecialitiesData.name"
              autocomplete="off"
              placeholder="Название"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Описание:"
          label-for="description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="description"
            vid="description"
          >
            <b-form-textarea
              id="description"
              v-model="doctorsSpecialitiesData.description"
              placeholder="Введите описание"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Дополнительное описание:"
          label-for="additional_description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="additional_description"
            vid="additional_description"
          >
            <b-form-textarea
              id="description"
              v-model="doctorsSpecialitiesData.additional_description"
              placeholder="Введите дополнительное описание"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Тип:"
          label-for="type"
        >
          <ValidationProvider
            #default="{ errors }"
            name="type"
            vid="type"
          >
            <b-form-select
              id="is_show"
              v-model="doctorsSpecialitiesData.type"
              value-field="id"
              text-field="value"
              :options="DoctorSpecialitiesConstants.DOCTOR_SPECIALITIES_TYPE_LIST"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Slug DOCDOC:"
          label-for="docdoc_slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="docdoc_slug"
            vid="docdoc_slug"
          >
            <b-form-input
              id="docdoc_slug"
              v-model="doctorsSpecialitiesData.docdoc_slug"
              autocomplete="off"
              placeholder="Введите Slug с DOCDOC"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ doctorsSpecialitiesData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormSelect,
} from 'bootstrap-vue'
import DoctorSpecialitiesConstants from '@/consts/doctorSpecialities'

export default {
  name: 'DoctorsSpecialitiesForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
  },
  props: {
    doctorsSpecialitiesData: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      DoctorSpecialitiesConstants,
    }
  },
  computed: {},
  watch: {},
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>
