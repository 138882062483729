const DOCTOR_SPECIALITIES_TYPE_IDS = {
  TYPE_ADULT: 0, // взрослый
  TYPE_CHILDREN: 1, // детский
  TYPE_DANTIST: 2, // зубной
}

const DOCTOR_SPECIALITIES_TYPE_LIST = [
  { id: DOCTOR_SPECIALITIES_TYPE_IDS.TYPE_ADULT, value: 'Взрослый' },
  { id: DOCTOR_SPECIALITIES_TYPE_IDS.TYPE_CHILDREN, value: 'Детский' },
  { id: DOCTOR_SPECIALITIES_TYPE_IDS.TYPE_DANTIST, value: 'Зубной' },
]
export default {
  // Тип специальности доктора
  DOCTOR_SPECIALITIES_TYPE_IDS,
  DOCTOR_SPECIALITIES_TYPE_LIST,
}
